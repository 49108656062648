import React,{useState} from 'react'
import {graphql, Link} from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import { buildBreadcrumbsItem } from "../helpers.js"
import PostCard from "../components/contentful/post-card";
import { productCategoryUrl, postClusterUrl } from "../helpers";
import BaseModal from "../components/modals/base-modal";
import {slice, concat} from 'lodash';

const ContentModal = () => {
  return (
    <div className='content-modal cbd-cluster-page row'>
      <div className="col-12 col-lg-8 mx-auto">
        <div className="subtitle"><p>You've heard about <a href="https://www.honestpaws.com/collections/cbd-oil-for-dogs/" target="_blank">CBD oil for dogs,</a> and you're wondering if it will do your pet any good?<br/>Keep reading to learn more about this holistic option for pets.</p></div>

        <p>We know you love your pet and want the very best for them. With shorter lifespans than humans, pet parents are willing to pull out all the stops to provide wellness care. Cannabidiol from hemp boasts many benefits for canine and feline bodies. Have you tried it for your animal?</p>
        <p>Almost <a href="https://www.brightfieldgroup.com/pet-cbd-landscape" target="_blank">60%</a> of pet owners who buy CBD for their animals use CBD for themselves too. They want to share the benefits of the cannabis plant and help their pet feel calmer, more active, and relieved of discomfort.</p>
        <p>For the 40% of pet owners who have not given CBD a try, there are many questions on its safety, potency, and benefits. Keep reading to learn more about this holistic option for pets.</p>

        <h2>What is CBD Oil?</h2>
        <p>Cannabidiol (CBD) oil is a naturally-occurring plant compound of the Cannabis Sativa plant. The Cannabis plant species are complex and offer a range of over 400 chemical entities, including CBD. This plant compound has <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6161644/" target="_blank">therapeutic</a> value and does not produce the typical psychoactive effects of marijuana, of which THC is responsible.</p>
        <p>Hemp-derived CBD is <a href="https://www.honestpaws.com/blog/is-cbd-oil-legal/" target="_blank">legal</a> in the U.S., which, according to the Farm Bill of <a href="https://www.brookings.edu/blog/fixgov/2018/12/14/the-farm-bill-hemp-and-cbd-explainer/" target="_blank">2018</a>, should contain 0.3% or less THC. When consumed, CBD affects the endocannabinoid system of mammals to support various roles in the body.</p>

        <h2>The Endocannabinoid System in Dogs and Cats</h2>
        <p>The endocannabinoid system works with a network of receptors throughout the body to provide a stable environment. It manages your sleep, hormones, cell growth, reproduction, mood, immune function, and <a href="https://www.honestpaws.com/blog/the-endocannabinoid-system-and-cbd/" target="_blank">more.</a> CBD interacts with endocannabinoids to bring support to these functions and make them work more effectively.</p>
        <p>The identified receptors are CB1, present in the brain, nervous system, glands, and organs; and CB2 receptors, found in regulatory and immune systems.</p>

        <h2><a href="https://www.honestpaws.com/cbd/is-cbd-oil-safe-for-dogs/" target="_blank">Is CBD Oil Safe for Dogs?</a></h2>
        <img src="/img/hub-clusters/cbd/image2.jpg" alt="dog and cat" className="w-100 my-3" data-lazy-class="img-solid" loading="lazy" />
        <p>Yes, yes, and yay! CBD from the hemp plant is perfectly safe for your cat or dog. With 0.3% or less THC oil, cannabidiol cannot make your pet trippy. One of the reasons CBD is a popular holistic option is that there are very few reported side effects.</p>
        <p>However, some pets are sensitive and can display non-threatening issues. These symptoms are short-lived, and you can avoid them by adjusting your pet's CBD dosage. <a href="https://www.honestpaws.com/cbd/side-effects-of-cbd-oil-in-dogs/" target="_blank">Side effects</a> may include dry mouth, low blood pressure, and diarrhea.</p>

        <h2>How Can CBD Oil Help My Pet?</h2>
        <p>CBD offers a plethora of benefits for your pet's body and wellness.</p>
        <h3><a href="https://www.honestpaws.com/cbd/cbd-oil-for-dogs-with-anxiety/" target="_blank">CBD Oil for Dogs With Anxiety</a></h3>
        <p>For stressed-out animals, CBD may support normal emotional balance. Whether behavioral or situational, CBD can calm your pet down and keep them relaxed. It may help your pet who is struggling with separation anxiety, sleeplessness, travel stress, or social <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7699613/" target="_blank">anxiety.</a></p>
        <h3><a href="https://www.honestpaws.com/cbd/cbd-oil-for-dogs-with-cancer/" target="_blank">CBD Oil for Dogs With Cancer</a></h3>
        <p>CBD has <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7693730/" target="_blank">anti-tumor effects,</a> reduces discomfort, and improves overall immune health. For dogs with <a href="https://www.honestpaws.com/cbd/cbd-oil-for-dogs-with-cancer/" target="_blank">cancer,</a> vets may even recommend CBD oil for pets, as it fits in well with many treatment plans by mitigating unpleasant side effects and enhancing the effectiveness of certain medications.</p>
        <p>Always speak to your vet before adding CBD to your animal's wellness routine to make sure it will not interfere with any current medications. Since CBD studies in pets are ongoing and generally lacking, some vets may not outright endorse or discourage the use of CBD hemp products.</p>
        <h3><a href="https://www.honestpaws.com/cbd/cbd-oil-for-dog-seizures/" target="_blank">CBD Oil for Dog Seizures</a></h3>
        <p>If you want to help your <a href="https://www.honestpaws.com/cbd/cbd-oil-for-dog-seizures/" target="_blank">dog</a> or cat reduce his seizures' frequency and intensity, a CBD pet product may help. It is a natural option that steers clear of the side effects of anti-seizure drugs. CBD supports normal brain and nerve function.</p>
        <h3><a href="https://www.honestpaws.com/cbd/cbd-oil-for-dog-allergies/" target="_blank">CBD Oil For Dog Allergies</a></h3>
        <p>Does your pet have sensitive skin, or are they susceptible to seasonal changes? If you live with <a href="https://www.petmd.com/dog/general-health/food-allergies-vs-seasonal-allergies-dogs" target="_blank">allergies,</a> then you know how bothersome it can be. Help your pet by giving them CBD.</p>
        <p>It helps maintain the skin's normal moisture content, supports a healthy inflammatory response, and soothes sore and irritated skin.</p>
        <h3>Additional Dog Ailments CBD Oil can Help With</h3>
        <ul>
          <li>Arthritis leaves your pet's joints inflamed and in pain. <a href="https://www.honestpaws.com/cbd/best-cbd-oil-for-dogs-with-arthritis/" target="_blank">The best CBD oil for dogs with arthritis</a> may act as an anti-inflammatory, and some owners have even found that they could reduce their pet's NSAID dose, thereby lessening NSAIDs' adverse effects.</li>
          <li><a href="https://www.honestpaws.com/cbd/cbd-oil-for-hyper-dogs/" target="_blank">CBD oil for hyper dogs</a> has calming effects that may reduce hyperactivity and promote relaxation in dogs.</li>
          <li><a href="https://www.honestpaws.com/cbd/cbd-oil-for-glaucoma-in-dogs/" target="_blank">CBD oil for glaucoma in dogs</a> has been shown to effectively reduce cell death, discomfort, and anxiety that accompanies many diseases, Glaucoma included.</li>
        </ul>

        <h2>How Much CBD Oil Should I Give My Pet?</h2>
        <p>There is no clear-cut answer to this question. Every pet is different and has unique needs. Pets who are ill and living with chronic conditions may require a larger serving size than a dog who needs CBD to keep them calm for a car trip.</p>
        <h3><a href="https://www.honestpaws.com/cbd/cbd-dosage-for-dogs/" target="_blank">CBD Dosage for Dogs</a></h3>
        <p>A general guideline of CBD dosing falls within the range of 1-5 mg of CBD per 10lbs of body weight for dogs. You can use our <a href="https://www.honestpaws.com/cbd/cbd-oil-for-dogs-dose-calculator/" target="_blank">CBD oil for dogs dose calculator</a> for a clear number. A 70-lb dog, therefore, could receive anywhere from 7-35 mg of CBD a day. Pet parents may choose to give their pet a stronger dose depending on their dog's ailment.</p>
        <h3>Understanding Potency</h3>
        <p>When you're shopping for CBD products, you'll notice that different strengths are available. You can buy 125 mg bottles, 250 mg bottles, 500 mg tinctures, and 1000 mg of CBD oil.</p>
        <p>These measurements refer to the <a href="https://www.honestpaws.com/blog/honest-paws-cbd-dosage-and-potency-levels/" target="_blank">potency</a> of CBD in the entire bottle. It's common for small oil tinctures to come with a 1 mL dropper.</p>
        <p>Here is a breakdown of the amount of CBD in each of our tinctures at Honest Paws:</p>
        <ul>
          <li>Our 125 mg strength bottle of CBD oil (for small dogs under 25lbs) contains 4 mg of CBD oil in a full 1 mL dropper.</li>
          <li>Our 250 mg strength bottle of CBD oil (for medium dogs around 25-50lbs) contains 8 mg of CBD oil in a full 1 mL dropper.</li>
          <li>Our 500 mg strength bottle of CBD oil (for large dogs over 50lbs) contains 16 mg of CBD oil in a full 1 mL dropper.</li>
          <li>Our 10000 mg strength bottle of CBD oil (for extra strength) contains 33 mg of CBD oil in a full 1 mL dropper.</li>
        </ul>
        <p>Finding a dosage that works on a full or half dropper measurement makes it easy to administer. Alternatively, you can choose to buy CBD dog treats with a set measurement of CBD per bite. While edibles absorb into the body a little slower than sublingual oil administration, they are convenient and require no careful measuring.</p>
        <p><a href="https://www.honestpaws.com/cbd/can-a-dog-overdose-on-cbd-oil/" target="_blank">A dog cannot overdose on CBD oil.</a> However, follow the dosing chart on the back of your dog’s CBD oil bottle to avoid side effects, such a lethargy, excessive thirst, diarrhea, and low blood pressure.</p>
        <p>When adding a new holistic option or supplement to your pet’s life, it’s always best to start with a low dose and slowly increase it. That way, you can notice any <a href="https://www.honestpaws.com/cbd/side-effects-of-cbd-oil-in-dogs/" target="_blank">side effects of CBD oil in dogs,</a> help your animal deal with them and adjust the amount you give your pet.</p>
        <h3>CBD Dosage for Cats</h3>
        <img src="/img/hub-clusters/cbd/image4.jpg" alt="cat dosage" className="w-100 my-3" data-lazy-class="img-solid" loading="lazy" />
        <p>Since cats are all of a similar weight, the proper amount of CBD is usually similar. We generally advise about .25mg of CBD per pound of feline body weight. Meaning if your cat is 10 lbs, they should receive 2.5mg of CBD per day.</p>
        <p>From a 125mg potency, one dropper a day translates to 4mg of CBD a day which most cats generally tolerate.</p>
        <h3>Dosage Tip for Cats and Dogs</h3>
        <p>We know you want to see results in your furry friend, but don't go ahead and offer them the highest dose on the first day. Any new addition to your pet's wellness routine will take some adjusting.</p>
        <p>We advise that you start with the smallest serving size for your pet's weight and incrementally increase it, as long as no side effects show. Before you know it, your cat or dog will be reaping the benefits of CBD oil. It may time a few weeks, so be patient and consistently dose them for the best results.</p>
        <h3><a href="https://www.honestpaws.com/cbd/how-to-give-cbd-oil-to-dogs/" target="_blank">How to Give CBD Oil to Dogs</a></h3>
        <img src="/img/hub-clusters/cbd/image1.jpg" alt="dog and woman" className="w-100 my-3" data-lazy-class="img-solid" loading="lazy" />
        <p>There are a few options to choose from when you want to give your pet CBD.</p>
        <h3>Full Spectrum CBD Hemp Oil</h3>
        <p>You can administer CBD oil to your pet in several ways:</p>
        <ul>
          <li>Sublingual (under the tongue) administration will have the quickest results. Draw the oil into the dropper and squirt it as far back as you can into their mouth. This method is perfect if your pet is compliant.</li>
          <li>Use a porous dog treat. Drip the oil onto your pet's favorite treat, allow it to soak in, and let them gobble it up.</li>
          <li>Offer CBD oil to your pet with their main meal.</li>
        </ul>
        <h3>CBD Soft Bites or Chews</h3>
        <p>CBD dog treats boast incredible flavors that appeal to the canine or feline palate. CBD treats for <a href="https://www.honestpaws.com/products/glucosamine-for-dogs/" target="_blank">mobility</a> may include natural ingredients like Glucosamine HCI, Chondroitin Sulfate, and Hyaluronic Acid, all known to help with cartilage and bone function and movement.</p>
        <p>By baking a little tidbit for your pet, CBD companies can add other beneficial ingredients to their product. Treats are easy to offer animals and are often a favorable choice.</p>
        <h3>CBD Peanut Butter</h3>
        <p>Can any dog resist a good smear of peanut butter? Many pets love it as a snack, and it's also a great option if you want to add something to your pet's Kong toy. <a href="https://www.honestpaws.com/products/cbd-peanut-butter-for-dogs/">CBD peanut butter</a> is xylitol-free and contains high-quality CBD oil.</p>
        <h3>Use Topicals</h3>
        <p>If your doggo or kitty is struggling with dry patches, itchy bites, or sensitive skin, a <a href="https://www.honestpaws.com/products/dog-paw-balm/" target="_blank">CBD Paw Balm</a> is easy to use and provides the soothing benefits of coconut oil and CBD oil.</p>
        <p>Whatever method you go for, consider your pet's personality and preferences.</p>

        <h2><a href="https://www.honestpaws.com/cbd/best-cbd-oil-for-dogs" target="_blank">Best CBD Oil for Dogs</a></h2>
        <p>Choosing a CBD product for your pet can be pretty overwhelming. Unfortunately, it's common for the CBD market to misguide consumers and share miracle claims about CBD.</p>
        <p>Research by the Food and Drug Administration (FDA) <a href="https://hempindustrydaily.com/wp-content/uploads/2020/07/CBD-Marketplace-Sampling_RTC_FY20_Final.pdf" target="_blank">looked</a> at 34 CBD products and found 48% of the products contained THC and other contaminants. As you can see, it's crucial to cautiously shop when you're looking for CBD oil for dogs and cats.</p>
        <p>Here are a few non-negotiables to look out for when it comes to buying CBD for pets:</p>
        <h3>Reviews and Reputation</h3>
        <p>Find out what people are saying about the brand in question. Some CBD hemp companies are associated with the Better Business Bureau, whose responsibility is to verify marketplace trust. A company with a high BBB rating has been vetted and gained a stamp of approval for trust.</p>
        <h3>Hemp Grown</h3>
        <p>When it comes to CBD, make sure the product is hemp-derived. This way, you can be confident that it is safe for your pet.</p>
        <p>If you are looking for CBD benefits, make sure you don't accidentally buy hemp seed products. While <a href="https://www.honestpaws.com/cbd/hemp-oil-vs-cbd-oil-for-dogs/" target="_blank">hemp seeds</a> will do wonders for your doggo's coat, it does not contain CBD. CBD products may use hemp seed oil as a carrier oil to profit from the whole hemp plant benefits.</p>
        <h3><a href="https://www.honestpaws.com/cbd/hemp-oil-vs-cbd-oil-for-dogs/" target="_blank">Hemp Seed Oil vs CBD Oil for Dogs</a></h3>
        <p>While the labels can sometimes be confusing, giving your pet hemp seed oil will not have the same benefits as whole-plant hemp oil. Hemp seed oil is still safe and suitable for your pet. Its omega fats will provide nourishment for their coat, making it healthier and shinier than before.</p>
        <h3>100% Natural and Organic</h3>
        <p>More than just making sure that the CBD oil is hemp-derived, you want to know that the fast-growing plant was grown in the very best circumstances. Farming practices make a big difference in the hemp plant quality and, consequently, the oil quality.</p>
        <p>Choose a brand that has used locally grown U.S. hemp on an organic farm that did not treat its crops with pesticides, fungicides, or solvents. </p>
        <p>Finally, choose a brand that creates human-grade oil that is 100% natural, non-GMO, and free of the common allergens of corn, soy, gluten, and dairy. The FDA has been stringent in CBD oil products for humans, ensuring human-grade products are safe and meet human consumption requirements. Your pet deserves the same.</p>
        <h3>Full Spectrum</h3>
        <p>You'll come across isolate CBD, broad-spectrum CBD, and <a href="https://www.honestpaws.com/blog/cbd-isolate-vs-full-spectrum-cbd-oil/" target="_blank">full-spectrum</a> CBD oils. An isolate oil ONLY contains CBD, excluding other beneficial hemp phytochemicals.</p>
        <p>While broad-spectrum and isolate oils can still help your pet, full-spectrum oil is a better option. It has multiple cannabinoids, flavinoids and terpenes that work together to provide a more substantial result.</p>
        <p>Full-spectrum CBD oil uses the Co2 extraction method. This <a href="https://www.honestpaws.com/blog/co2-extraction/" target="_blank">method</a> is the safest way to extract CBD and other cannabinoids from the cannabis plant. The result is a pure, clean, quality oil that involves little to no post-processing.</p>
        <h3>Lab Tested</h3>
        <p>So how do you know a brand is being honest about what they've put on their label? Reputable CBD companies send their products for testing at a third-party lab.</p>
        <p>They'll then receive a Certificate of Analysis (COA) that shows an in-depth report of the contents, including contaminants and THC levels. A trustworthy brand will share this information with you. You can ask to see their COA and ask them questions about their product.</p>

        <h2>Put Your Paws Together for CBD Pet Products</h2>
        <img src="/img/hub-clusters/cbd/image3.jpg" alt="cat and woman" className="w-100 my-3" data-lazy-class="img-solid" loading="lazy" />
        <p>So, <a href="https://www.honestpaws.com/cbd/can-you-give-dogs-cbd-oil/" target="_blank">can you give dogs CBD oil?</a> Yes,CBD is all that and more! While supporting the endocannabinoid system, it also provides a richness of vitamins and irons. It's a natural source of protein, packed with amino acids, vitamin B6, B12, C, E, riboflavin, niacin, and thiamine. It's a powerful antioxidant too and can help the body eliminate toxins, and protect your pet from illness and infection.</p>
        <p>The world of pet cannabis products is exploding, and we want to make sure you choose a high-quality product. Honest Paws brings you CBD products that are perfect for your cat, dog, or horse.</p>
        <p>Our products are non-GMO, organic, and contain full-spectrum CBD hemp oil. Our products have been lab-tested, and we'd be happy to share the results with you. Don't take our word for it though! Read our <a href="https://www.honestpaws.com/cbd/cbd-for-dogs-reviews/" target="_blank">CBD for dogs reviews</a> to see how our CBD oil measures up.</p>

        <h2>Frequently Asked Questions</h2>
        <h3>What is the best CBD oil for pets?</h3>
        <p>Honest Paws brings you CBD products that are perfect for your cat, dog, or horse. Our products are non-GMO, organic, and contain full-spectrum CBD hemp oil. Our products have been lab-tested, and we'd be happy to share the <a href="https://www.honestpaws.com/verify/" target="_blank">results</a> with you.</p>
        <h3><a href="https://www.honestpaws.com/cbd/can-humans-take-pet-cbd-oil/" target="_blank">Can humans take CBD oil for dogs?</a></h3>
        <p>Yes! Humans can actually take CBD oil for dogs.The FDA has been stringent in CBD oil products for humans, ensuring human-grade products are safe and meet human consumption requirements. Your pet deserves the best, so choose a pet CBD brand that used human-grade CBD oil. Just beware as you cannot share your CBD oil with your dog since it contains THC which can be deadly to pets.</p>
        <h3>Do vets recommend CBD oil for dogs?</h3>
        <p>Always speak to your vet before adding CBD to their wellness routine. Since CBD studies in pets are ongoing and generally lacking, some vets may not outright endorse or discourage the use of CBD products.</p>
        <h3><a href="https://www.honestpaws.com/cbd/can-drug-dogs-smell-cbd-oil/" target="_blank">Can drug dogs smell CBD oil?</a></h3>
        <p>Drug dogs can smell CBD oil, but because it is legal in most of the United States they are not actively trained to do so. </p>

      </div>
    </div>
  )
}

class CbdPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'CBD For Pets | Honest Paws®'
    this.pageUrl = this.props.location.href
    this.posts = get(this.props, 'data.posts.edges', []) || []
    this.ogImage = this.props.data.ogImage.childImageSharp.fluid

    this.state = {
      postsVisbles: 9,
      postsPerPage: 9,
      showMoreButton: true
    }

    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'CBD for pets is more popular than ever, as pet owners use CBD for dogs and CBD for cats to help with issues like anxiety, arthritis, and more. Learn more about the benefits of CBD for pets today!',
        image: this.ogImage
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url'  />
      ],
      disallowIndexing: true
    }

    this.modalContent = null
    this.displayContent = this.displayContent.bind(this)
    this.showMorePosts = this.showMorePosts.bind(this)
  }

  /**
   * Displays More Posts - Pagination
   */
  showMorePosts () {
    this.setState({
      postsVisbles: this.state.postsVisbles + this.state.postsPerPage,
      showMoreButton: this.posts.length > (this.state.postsVisbles + this.state.postsPerPage)
    })
  }

  /**
   * Displays content inside a modal
   * @param  {event} e
   * @return {void}
   */
  displayContent(e) {
    this.modalContent.showModal()
  }

  componentDidMount() {
    this.setState({
      showMoreButton: this.posts.length > this.state.postsPerPage
    })
  }

  render() {


    return (
      <Layout location={this.props.location} containerClassName={`page cbd-custom-page`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo } />

        <section className="page-title-and-summary text-center px-lg-4 py-4">
          <div>
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0 text-left mb-4">CBD For Pets</h1>
              <div className="container-content d-flex p-lg-5 px-lg-2 py-5">
                <div className="col-7 col-lg-5 offset-lg-1 text-left">
                  <p>You've heard about CBD, and you're wondering if it will do your cat or dog any good? Keep reading to learn more about this holistic option for pets</p>
                  <button  onClick={this.displayContent} className="btn btn-link">Keep Reading</button>
                  <BaseModal ref={(ref) => this.modalContent = ref} title="Learn More" children={<ContentModal />} size='lg' scrollable={true}></BaseModal>
                </div>
                <div className="col-5 col-lg-5 d-flex align-items-center justify-content-end">
                  <img src="/img/hub-clusters/cbd/Pets.svg" alt="dog and cat sleeping" data-lazy-class="img-solid" loading="lazy" />
                </div>
              </div>

              <div className="btn-container mt-5 d-none d-sm-flex">
                <div className="col-3 pl-0">
                  <a target="_top" href="https://www.honestpaws.com/collections/cbd-oil-for-dogs" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/cbd/dog-icon.svg" className="mx-auto mb-3" alt="honest paws cbd oil for dogs" data-lazy-class="img-solid" loading="lazy" />
                    CBD Oil for Dogs
                  </a>
                </div>
                <div className="col-3">
                  <a target="_top" href="https://www.honestpaws.com/collections/cbd-dog-treats" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/cbd/treats-icon.svg" className="mx-auto mb-3" alt="honest paws cbd dog treats" data-lazy-class="img-solid" loading="lazy" />
                    CBD Dog Treats
                  </a>
                </div>
                <div className="col-3">
                  <a target="_top" href="https://www.honestpaws.com/products/cbd-oil-for-cats/" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/cbd/cats-icon.svg" className="mx-auto mb-3" alt="honest paws cbd oil for cats" data-lazy-class="img-solid" loading="lazy" />
                    CBD Oil for Cats
                  </a>
                </div>
                <div className="col-3 pr-0">
                  <a target="_top" href="https://www.honestpaws.com/products/cbd-oil-for-horses/" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/cbd/horses-icon.svg" className="mx-auto mb-3" alt="honest paws cbd oil for horses" data-lazy-class="img-solid" loading="lazy" />
                    CBD Oil for Horses
                  </a>
                </div>
              </div>

              <div className="btn-container mt-4 d-sm-none row d-flex">
                <div className="col-6">
                  <a target="_top" href="https://www.honestpaws.com/collections/cbd-oil-for-dogs" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/cbd/dog-icon.svg"className="mx-auto mb-3"  alt="honest paws cbd oil for dogs" data-lazy-class="img-solid" loading="lazy" />
                    CBD Oil <br/>for Dogs
                  </a>
                </div>
                <div className="col-6">
                  <a target="_top" href="https://www.honestpaws.com/collections/cbd-dog-treats" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/cbd/treats-icon.svg"className="mx-auto mb-3"  alt="honest paws cbd dog treats" data-lazy-class="img-solid" loading="lazy" />
                    CBD Dog <br/>Treats
                  </a>
                </div>
                <div className="col-6 mt-3">
                  <a target="_top" href="https://www.honestpaws.com/products/cbd-oil-for-cats/" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/cbd/cats-icon.svg"className="mx-auto mb-3"  alt="honest paws cbd oil for cats" data-lazy-class="img-solid" loading="lazy" />
                    CBD Oil <br/>for Cats
                  </a>
                </div>
                <div className="col-6 mt-3">
                  <a target="_top" href="https://www.honestpaws.com/products/cbd-oil-for-horses/" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/cbd/horses-icon.svg"className="mx-auto mb-3"  alt="honest paws cbd oil for horses" data-lazy-class="img-solid" loading="lazy" />
                    CBD Oil <br/>for Horses
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white pt-0 px-lg-4">
          <div className="page-content-container">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 px-0 mt-5 d-flex flex-wrap">
              {this.posts.slice(0, this.state.postsVisbles).map(({node}, index) => {
                return <PostCard
                  title={ node.title }
                  url={ postClusterUrl(node) }
                  image={ node.heroImage }
                  shortDescription={ node.shortDescription }
                  key={ index }
                  version={2}
                  bgColor="bg-light"
                  className="col-12 col-sm-6 col-md-4 col-xl-4 mb-5" />
              })}
              <div className="col-12 text-center">
                <button onClick={this.showMorePosts} className={`btn btn-outline show-more ${!this.state.showMoreButton ? "d-none" : ""}`}>Show More</button>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    )
  }
}

export default CbdPage

export const CbdPageQuery = graphql`
  query CbdPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    posts: allContentfulBlogPost(filter: {cluster: {eq: "cbd"}}) {
      edges {
        node {
          ...contentfulBlogPost
        }
      }
    }
    ogImage: file(relativePath: { eq: "hub-clusters/cbd/og-image-cbd-cluster.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
